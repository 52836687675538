<template>
  <v-container class="mt-0 pt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card :loading="loading" :disabled="loading" outlined>
          <v-card-title>
            <v-icon class="mr-3" color="primary">mdi-group</v-icon>
            <h3>Editar Tag</h3>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" :to="{ name: 'tag-list' }" exact>
              Voltar
            </v-btn>
          </v-card-title>
          <v-row>
            <v-card-text>
              <v-col cols="12">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-text-field
                    v-model="getTag.name"
                    label="Nome"
                    :rules="nameRules"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                  <v-card outlined>
                    <v-card-text class="subtitle-1">
                      Selecione um icone
                    </v-card-text>
                    <div class="pa-4">
                      <v-chip-group
                        v-model="selectedIcon"
                        active-class="primary"
                        column
                      >
                        <v-chip
                          v-for="icon in icons"
                          :key="icon.name"
                          :value="icon.name"
                        >
                          <v-icon>{{ icon.name }}</v-icon>
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </v-card>
                </v-form>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn
                  color="primary"
                  @click="updateTagItem"
                  :loading="creating"
                  :disabled="creating"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: true,
      selectedIcon: {},
      getTag: {},
      creating: false,
      valid: true,
      nameRules: [v => !!v || "Nome obrigatório"]
    };
  },

  async mounted() {
    await this.loadSelectedTag({
      id: this.$route.params.id
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.getTag = this.getSelectedTag;
    this.selectedIcon = this.getSelectedTag.icon;
    this.loading = false;
  },

  computed: {
    ...mapState("icon", ["icons"]),
    ...mapState("tag", ["tag_update"]),
    ...mapGetters("tag", ["getSelectedTag"])
  },
  methods: {
    ...mapActions("tag", ["loadSelectedTag", "updateTag"]),

    async updateTagItem() {
      this.selectedIcon =
        this.selectedIcon === undefined ? "" : this.selectedIcon;
      if (this.$refs.form.validate()) {
        this.creating = true;
        const params = {
          name: this.getTag.name,
          icon: this.selectedIcon,
          id: this.$route.params.id
        };
        await this.updateTag(params).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        if (this.tag_update.success) {
          this.$swal({
            icon: "success",
            title: this.tag_update.message,
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Listagem",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#445E93",
            showLoaderOnConfirm: true
          }).then(result => {
            if (!result.value) {
              this.$router.push({
                name: "tag-list"
              });
            }
          });
        } else {
          this.$swal("Oops ...", this.tag_update.message, "warning");
        }
        this.creating = false;
      }
    }
  }
};
</script>
